'use strict';

angular.module('enervexSalesappApp').controller('CustomerContactCtrl', function($scope, $state, CustomerContact, CustomerCompany, $stateParams, Flash, _) {
	$scope.errors = {};

	function fetchCompany() {
		CustomerCompany.get({
			id: $stateParams.companyId
		}).$promise.then(function(company) {
			$scope.company = company;
			fetchUser()
		});
	}
	function fetchUser() {
		if ($stateParams.id == "new") {
			$scope.user = {
				"RepCo": $scope.company.ID,
				"Name": "",
				"Email": "",
				"Password": "",
				"ID": "",
				"Created": "",
				"Revised": "",
				"OldID": "",
				"Title": "",
				"Cell": "",
				"Ext": "",
				"License": "00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000",
				"Devices": 0,
				"FCOption": "0",
				"Categories": "",
				"LastWebLogon": "",
				"LastComplete": "",
				"OfflineDays": 0,
				"Blocked": 0,
				"Training Notification": 0,
				"License Issued": 1,
				"Notify Rep": 1,
				"Sales Reports": 1,
				"Email User": 1,
				"Email Admin": 1,
				"VB Export": 0
			}
		} else {
			CustomerContact.get({
				id: $stateParams.id
			}).$promise.then(function(user) {
				$scope.user = user;
			});
		}
	}
	fetchCompany();
	$scope.save = function(form){
		Flash.clear()
		var payload = _.omit($scope.user, "company");
		payload = _.omit(payload, "__v");
		if (form.$valid){
			if (!payload.ID) {
				payload.ID = UUIDjs.create(4).toString();
				CustomerContact.save({}, payload).$promise.then(function(res){
					$state.go('customer-contacts', {
						companyId: $scope.company.ID
					}, {
						reload: true,
						inherit: false
					})
				}).catch(function(err){
					$scope.errors.other = err.message;
					var message = err.message;
					Flash.create('warning', message, 0, {}, true);
				});
			} else {
				CustomerContact.update({
					id: payload.ID
				}, payload).$promise.then(function(res){
					$state.go('customer-contacts', {
						companyId: $scope.company.ID
					}, {
						reload: true,
						inherit: false
					})
				}).catch(function(err){
					$scope.errors.other = err.message;
					var message = err.message;
					Flash.create('warning', message, 0, {}, true);
				});
			}
		} else {
			Flash.create('warning', "invalid form", 0, {}, true);
		}
	}
});
